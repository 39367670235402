import React from 'react';
import RdvContainer from '../containers/RdvContainer';
import featureSwitch from '../../../services/featureSwitch';
import routes from '../../../Routes';
import Layout from '../../../layouts/index';

const nextStep = featureSwitch('step5_skip') ?
  routes.EstimationVariantInfos.url : routes.EstimationVariantLogin.url;
const Step4Rdv = () => (
  <Layout slug="EstimationVariantRdv">
    <RdvContainer nextStep={nextStep} />
  </Layout>
);
export default Step4Rdv;
